/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  --ion-text-color: #3274ae;
  --ion-text-color-rgb: 50,116,174;

  --ion-color-step-50: #f5f8fb;
  --ion-color-step-100: #ebf1f7;
  --ion-color-step-150: #e0eaf3;
  --ion-color-step-200: #d6e3ef;
  --ion-color-step-250: #ccdceb;
  --ion-color-step-300: #c2d5e7;
  --ion-color-step-350: #b7cee3;
  --ion-color-step-400: #adc7df;
  --ion-color-step-450: #a3c0db;
  --ion-color-step-500: #99bad7;
  --ion-color-step-550: #8eb3d2;
  --ion-color-step-600: #84acce;
  --ion-color-step-650: #7aa5ca;
  --ion-color-step-700: #709ec6;
  --ion-color-step-750: #6597c2;
  --ion-color-step-800: #5b90be;
  --ion-color-step-850: #5189ba;
  --ion-color-step-900: #4782b6;
  --ion-color-step-950: #3c7bb2;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** heal **/
  --ion-color-heal: #ddf3eb;
  --ion-color-heal-rgb: 221,243,235;
  --ion-color-heal-contrast: #000000;
  --ion-color-heal-contrast-rgb: 0,0,0;
  --ion-color-heal-shade: #c2d6cf;
  --ion-color-heal-tint: #e0f4ed;

  /** pink **/
  --ion-color-pink: #e9c5d3;
  --ion-color-pink-rgb: 233,197,211;
  --ion-color-pink-contrast: #000000;
  --ion-color-pink-contrast-rgb: 0,0,0;
  --ion-color-pink-shade: #cdadba;
  --ion-color-pink-tint: #ebcbd7;

  /** purple **/
  --ion-color-purple: #e1d3f3;
  --ion-color-purple-rgb: 225,211,243;
  --ion-color-purple-contrast: #000000;
  --ion-color-purple-contrast-rgb: 0,0,0;
  --ion-color-purple-shade: #c6bad6;
  --ion-color-purple-tint: #e4d7f4;

  /** yellow **/
  --ion-color-yellow: #fbf4d0;
  --ion-color-yellow-rgb: 251,244,208;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0,0,0;
  --ion-color-yellow-shade: #ddd7b7;
  --ion-color-yellow-tint: #fbf5d5;

  /** healing **/
  --ion-color-healing: #c6d0ea;
  --ion-color-healing-rgb: 198,208,234;
  --ion-color-healing-contrast: #000000;
  --ion-color-healing-contrast-rgb: 0,0,0;
  --ion-color-healing-shade: #aeb7ce;
  --ion-color-healing-tint: #ccd5ec;

  /** healtwo **/
  --ion-color-healtwo: #c6b3cc;
  --ion-color-healtwo-rgb: 198,179,204;
  --ion-color-healtwo-contrast: #000000;
  --ion-color-healtwo-contrast-rgb: 0,0,0;
  --ion-color-healtwo-shade: #ae9eb4;
  --ion-color-healtwo-tint: #ccbbd1;

  /** healthree **/
  --ion-color-healthree: #becfb7;
  --ion-color-healthree-rgb: 190,207,183;
  --ion-color-healthree-contrast: #000000;
  --ion-color-healthree-contrast-rgb: 0,0,0;
  --ion-color-healthree-shade: #a7b6a1;
  --ion-color-healthree-tint: #c5d4be;

  /** healfour **/
  --ion-color-healfour: #b3c0b9;
  --ion-color-healfour-rgb: 179,192,185;
  --ion-color-healfour-contrast: #000000;
  --ion-color-healfour-contrast-rgb: 0,0,0;
  --ion-color-healfour-shade: #9ea9a3;
  --ion-color-healfour-tint: #bbc6c0;

  /** healfive **/
  --ion-color-healfive: #8aa7b6;
  --ion-color-healfive-rgb: 138,167,182;
  --ion-color-healfive-contrast: #000000;
  --ion-color-healfive-contrast-rgb: 0,0,0;
  --ion-color-healfive-shade: #7993a0;
  --ion-color-healfive-tint: #96b0bd;

  /** text **/
  --ion-color-text: #1895a5;
  --ion-color-text-rgb: 24,149,165;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255,255,255;
  --ion-color-text-shade: #158391;
  --ion-color-text-tint: #2fa0ae;

  /** texttwo **/
  --ion-color-texttwo: #3274ae;
  --ion-color-texttwo-rgb: 50,116,174;
  --ion-color-texttwo-contrast: #ffffff;
  --ion-color-texttwo-contrast-rgb: 255,255,255;
  --ion-color-texttwo-shade: #2c6699;
  --ion-color-texttwo-tint: #4782b6;

  /** textthree **/
  --ion-color-textthree: #2e82cc;
  --ion-color-textthree-rgb: 46,130,204;
  --ion-color-textthree-contrast: #ffffff;
  --ion-color-textthree-contrast-rgb: 255,255,255;
  --ion-color-textthree-shade: #2872b4;
  --ion-color-textthree-tint: #438fd1;

  /** textfour **/
  --ion-color-textfour: #438ccb;
  --ion-color-textfour-rgb: 67,140,203;
  --ion-color-textfour-contrast: #ffffff;
  --ion-color-textfour-contrast-rgb: 255,255,255;
  --ion-color-textfour-shade: #3b7bb3;
  --ion-color-textfour-tint: #5698d0;

   /** textfive **/
   --ion-color-textfive: #3970d5;
   --ion-color-textfive-rgb: 57,112,213;
   --ion-color-textfive-contrast: #ffffff;
   --ion-color-textfive-contrast-rgb: 255,255,255;
   --ion-color-textfive-shade: #3263bb;
   --ion-color-textfive-tint: #4d7ed9;

   --ion-color-blueone: #6dcac9;
   --ion-color-blueone-rgb: 109,202,201;
   --ion-color-blueone-contrast: #000000;
   --ion-color-blueone-contrast-rgb: 0,0,0;
   --ion-color-blueone-shade: #60b2b1;
   --ion-color-blueone-tint: #7ccfce;

   --ion-color-bluetwo: #3895bc;
   --ion-color-bluetwo-rgb: 56,149,188;
   --ion-color-bluetwo-contrast: #ffffff;
   --ion-color-bluetwo-contrast-rgb: 255,255,255;
   --ion-color-bluetwo-shade: #3183a5;
   --ion-color-bluetwo-tint: #4ca0c3;

   --ion-color-bluethree: #1f53b2;
   --ion-color-bluethree-rgb: 31,83,178;
   --ion-color-bluethree-contrast: #ffffff;
   --ion-color-bluethree-contrast-rgb: 255,255,255;
   --ion-color-bluethree-shade: #1b499d;
   --ion-color-bluethree-tint: #3564ba;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-bluethree {
  --ion-color-base: var(--ion-color-bluethree);
  --ion-color-base-rgb: var(--ion-color-bluethree-rgb);
  --ion-color-contrast: var(--ion-color-bluethree-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bluethree-contrast-rgb);
  --ion-color-shade: var(--ion-color-bluethree-shade);
  --ion-color-tint: var(--ion-color-bluethree-tint);
}

.ion-color-bluetwo {
  --ion-color-base: var(--ion-color-bluetwo);
  --ion-color-base-rgb: var(--ion-color-bluetwo-rgb);
  --ion-color-contrast: var(--ion-color-bluetwo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bluetwo-contrast-rgb);
  --ion-color-shade: var(--ion-color-bluetwo-shade);
  --ion-color-tint: var(--ion-color-bluetwo-tint);
}

.ion-color-blueone {
  --ion-color-base: var(--ion-color-blueone);
  --ion-color-base-rgb: var(--ion-color-blueone-rgb);
  --ion-color-contrast: var(--ion-color-blueone-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blueone-contrast-rgb);
  --ion-color-shade: var(--ion-color-blueone-shade);
  --ion-color-tint: var(--ion-color-blueone-tint);
}

.ion-color-textfive {
  --ion-color-base: var(--ion-color-textfive);
  --ion-color-base-rgb: var(--ion-color-textfive-rgb);
  --ion-color-contrast: var(--ion-color-textfive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-textfive-contrast-rgb);
  --ion-color-shade: var(--ion-color-textfive-shade);
  --ion-color-tint: var(--ion-color-textfive-tint);
}

.ion-color-heal {
  --ion-color-base: var(--ion-color-heal);
  --ion-color-base-rgb: var(--ion-color-heal-rgb);
  --ion-color-contrast: var(--ion-color-heal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-heal-contrast-rgb);
  --ion-color-shade: var(--ion-color-heal-shade);
  --ion-color-tint: var(--ion-color-heal-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-healing {
  --ion-color-base: var(--ion-color-healing);
  --ion-color-base-rgb: var(--ion-color-healing-rgb);
  --ion-color-contrast: var(--ion-color-healing-contrast);
  --ion-color-contrast-rgb: var(--ion-color-healing-contrast-rgb);
  --ion-color-shade: var(--ion-color-healing-shade);
  --ion-color-tint: var(--ion-color-healing-tint);
}

.ion-color-healtwo {
  --ion-color-base: var(--ion-color-healtwo);
  --ion-color-base-rgb: var(--ion-color-healtwo-rgb);
  --ion-color-contrast: var(--ion-color-healtwo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-healtwo-contrast-rgb);
  --ion-color-shade: var(--ion-color-healtwo-shade);
  --ion-color-tint: var(--ion-color-healtwo-tint);
}

.ion-color-healthree {
  --ion-color-base: var(--ion-color-healthree);
  --ion-color-base-rgb: var(--ion-color-healthree-rgb);
  --ion-color-contrast: var(--ion-color-healthree-contrast);
  --ion-color-contrast-rgb: var(--ion-color-healthree-contrast-rgb);
  --ion-color-shade: var(--ion-color-healthree-shade);
  --ion-color-tint: var(--ion-color-healthree-tint);
}

.ion-color-healfour {
  --ion-color-base: var(--ion-color-healfour);
  --ion-color-base-rgb: var(--ion-color-healfour-rgb);
  --ion-color-contrast: var(--ion-color-healfour-contrast);
  --ion-color-contrast-rgb: var(--ion-color-healfour-contrast-rgb);
  --ion-color-shade: var(--ion-color-healfour-shade);
  --ion-color-tint: var(--ion-color-healfour-tint);
}

.ion-color-healfive {
  --ion-color-base: var(--ion-color-healfive);
  --ion-color-base-rgb: var(--ion-color-healfive-rgb);
  --ion-color-contrast: var(--ion-color-healfive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-healfive-contrast-rgb);
  --ion-color-shade: var(--ion-color-healfive-shade);
  --ion-color-tint: var(--ion-color-healfive-tint);
}

.ion-color-text {
  --ion-color-base: var(--ion-color-text);
  --ion-color-base-rgb: var(--ion-color-text-rgb);
  --ion-color-contrast: var(--ion-color-text-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-shade);
  --ion-color-tint: var(--ion-color-text-tint);
}

.ion-color-texttwo {
  --ion-color-base: var(--ion-color-texttwo);
  --ion-color-base-rgb: var(--ion-color-texttwo-rgb);
  --ion-color-contrast: var(--ion-color-texttwo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-texttwo-contrast-rgb);
  --ion-color-shade: var(--ion-color-texttwo-shade);
  --ion-color-tint: var(--ion-color-texttwo-tint);
}

.ion-color-textthree {
  --ion-color-base: var(--ion-color-textthree);
  --ion-color-base-rgb: var(--ion-color-textthree-rgb);
  --ion-color-contrast: var(--ion-color-textthree-contrast);
  --ion-color-contrast-rgb: var(--ion-color-textthree-contrast-rgb);
  --ion-color-shade: var(--ion-color-textthree-shade);
  --ion-color-tint: var(--ion-color-textthree-tint);
}

.ion-color-textfour {
  --ion-color-base: var(--ion-color-textfour);
  --ion-color-base-rgb: var(--ion-color-textfour-rgb);
  --ion-color-contrast: var(--ion-color-textfour-contrast);
  --ion-color-contrast-rgb: var(--ion-color-textfour-contrast-rgb);
  --ion-color-shade: var(--ion-color-textfour-shade);
  --ion-color-tint: var(--ion-color-textfour-tint);
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
