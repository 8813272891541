ion-col{
    --ion-grid-column-padding:15px;
}

ion-item{
    --min-height:80px;
}

ion-checkbox{
    --size:20Px;
    --border-width:2Px;
}

ion-chip{
    background-color:rgba(180, 227, 235, 0.721);
}

ion-chip.title{
    background-color:rgba(228, 240, 242, 0.892);
    height: 3em;
}

ion-chip.bigTitle{
    align-items: center;
    background-color:rgba(180, 227, 235, 0.721);
    height: 5em;
    width: 16.5em;
}

ion-chip.bigTitle1{
    align-items: center;
    background-color:rgba(180, 227, 235, 0.721);
    height: 5em;
    width: 18.5em;
}

ion-chip.bigTitle2{
    background-color:rgba(180, 227, 235, 0.721);
    height: 8em;
    width: 18.5em;
    align-items: center;
}

ion-chip.positive2{
    background-color:rgba(145, 191, 229, 0.721);
}

ion-chip.positive3{
    background-color:rgba(96, 174, 238, 0.721);
}

ion-row.conversationTitle{
    font-size: x-large;
    color: aliceblue;
}

.Tab2 {
    text-align: center;
}

.Tab2-title {
    font-size: 1.5em;
}

.background-image{
    --background: url(../img/ocean.jpg) 0 0/100% 100% no-repeat;
}

.background-image-theme{
    --background: url(../img/background-text.jpg) 0 0/100% 100% no-repeat;
}

.background-blue{
    --background: rgb(99, 206, 230) 0 0/100% 100% no-repeat;
    color: white;
}

.manageList{
    --background: rgb(99, 206, 230) 0 0/100% 100% no-repeat;
    color: white;
    text-transform: capitalize;
}

ion-button.button-length{
    width: 280px;
}

h3.blockText-white {
    margin-left: auto;
    margin-right: auto;
    width:7em;
    color:white;
}

h3.blockText2 {
    margin-left: auto;
    margin-right: auto;
    width:7em;
    color:rgb(226, 44, 166);
}

h3.blockText2-big {
    margin-left: auto;
    margin-right: auto;
    width:10em;
    color:rgba(88, 180, 208, 0.81);
}

h3.blockText3 {
    margin-left: auto;
    margin-right: auto;
    width:7em;
    color:rgba(155, 116, 216, 0.865);
}

h4.blockText-big {
    margin-left: auto;
    margin-right: auto;
    width:11em;
    color: rgb(41, 106, 155);
}

.white{
    color: white;
}

.calendar-style{
    font-weight: bold;
    color: white;
}
